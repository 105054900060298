import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatAdapter, createAzureCommunicationChatAdapter, ChatComposite, MessageProps, MessageRenderer } from "@azure/communication-react";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import moment from "moment";
import { RootState } from "common/redux/core/root.reducer";
import { agentsActions } from "modules/gpo-agent/redux/agents";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import Loader from "common/components/base/Loader/Loader";
import { bot } from "assets/media/images";

import "./Chat.scss";

const Chat = () => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector((state: RootState) => state.agents.selectedConversation);
  const aCSContext = useSelector((state: RootState) => state.agents.aCSContext.data);
  const session = useSelector((state: RootState) => state.agents.session.data);

  const [adapter, setAdapter] = useState<ChatAdapter>();

  useEffect(() => {
    if (selectedConversation) {
      dispatch(agentsActions.getACSContext());
    }
  }, [selectedConversation]);


  useEffect(() => {
    buildChatAdapter();
  }, [aCSContext]);

  useEffect(() => {
    buildChatAdapter();
  }, []);

  const buildChatAdapter = () => {
    if (aCSContext && aCSContext?.enpoint) {
      createAzureCommunicationChatAdapter({
        endpoint: aCSContext.enpoint,
        displayName: session?.displayName || '',
        threadId: selectedConversation?.conversationThreadId || '',
        userId: { communicationUserId: session?.guid || '' },
        credential: new AzureCommunicationTokenCredential(aCSContext.token)
      }).then(adapter => {
        setAdapter(adapter);
      });
    }
  }

  const renderMessage = (messageProps: MessageProps, defaultOnRender?: MessageRenderer) => {
    const msg = messageProps as any;
    if (msg?.message?.contentType === 'text') {
      if (msg?.message?.senderDisplayName === 'GPO Chat Bot') {
        return (
          <section className="bot">
            <header>
              <img src={bot} />
              <div className="name">
                <strong>Talent AI ChatBot</strong>
                <span>{moment.utc(msg?.message?.createdOn)?.local().fromNow()}</span>
              </div>
            </header>
            <p dangerouslySetInnerHTML={{__html: msg?.message?.content?.replace(/\n/g, "</br>")}}></p>
          </section>);
      } else {
        if (msg?.message?.senderDisplayName === selectedConversation?.displayName)
          return (
            <section className="user">
              <header>
                <ProfileImage profileId={selectedConversation?.userGuid || ''} />
                <div className="name">
                  <strong>{msg?.message?.senderDisplayName}</strong>
                  <span>{moment.utc(msg?.message?.createdOn)?.local().fromNow()}</span>
                </div>
              </header>
              <p dangerouslySetInnerHTML={{__html: msg?.message?.content?.replace(/\n/g, "</br>")}}></p>
            </section>
          );
        else
          return (
            <section className="agent">
              <header>
                <div className="name">
                  <strong>You</strong>
                  <span>{moment.utc(msg?.message?.createdOn)?.local().fromNow()}</span>
                </div>
              </header>
              <p dangerouslySetInnerHTML={{__html: msg?.message?.content?.replace(/\n/g, "</br>")}}></p>
            </section>)
      }

    } else if (msg?.message?.participants[0]?.displayName === session?.displayName) {
      return (
        <section className="new-agent">
          <div className="detail">
            <ProfileImage profileId={session?.guid || ''} />
            <span>
              <strong>{session?.displayName} </strong>joined the chat
            </span>
            <span>{moment.utc(msg?.message?.createdOn)?.local().fromNow()}</span>
          </div>
        </section>
      )
    } else {
      return (<></>)
    }
  }

  return (
    <div className="chat">
      <div className="chat__container">
        {adapter ? <ChatComposite adapter={adapter} onRenderMessage={renderMessage} /> : <Loader />}
        {/* <Loader /> */}
      </div>
    </div >
  );
};

export default Chat;

